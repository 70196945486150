import { rootKeys } from './rootKeys';

export interface IApplicationKeyParams {
  applicationID: string | undefined;
  marketCountry?: string | undefined;
  SSN?: string | undefined;
}

export const applicationKeys = {
  root: (params: IApplicationKeyParams) =>
    [...rootKeys.loanApplication(), params.applicationID] as const,
  children: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'application children'] as const,
  debt: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'application debt'] as const,
  detail: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'application detail'] as const,
  loanQuotes: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'loan quotes'] as const,
  loanQuotesPresentation: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'loan quotes presentation'] as const,
  loanRequest: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'loan request'] as const,
  locks: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), params.marketCountry, 'lock'] as const,
  lenderTier: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'lender tier'] as const,
  registrationStatus: (params: IApplicationKeyParams) =>
    [...applicationKeys.root(params), 'registration status'] as const,
  validation: (applicationID: string) =>
    [...applicationKeys.root({ applicationID }), 'validation'] as const,
};

export function parseApplicationKey<T extends keyof typeof applicationKeys>(
  queryKey: ReturnType<(typeof applicationKeys)[T]>,
) {
  const [, applicationID, marketCountry] = queryKey;

  return { applicationID, marketCountry };
}
