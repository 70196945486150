import { z } from 'zod';

export const LoanApplicationValidationSchema = z.union([
  z.object({
    Bad: z.literal(false),
  }),
  z.object({
    Bad: z.literal(true),
    Errors: z
      .array(
        z.object({
          Field: z.string(),
          Message: z.string(),
        }),
      )
      .optional(),
    Message: z.string().optional(),
  }),
]);

export type LoanApplicationValidation = z.infer<
  typeof LoanApplicationValidationSchema
>;
