import { classNames } from '@axo/shared/util/dom';
import { ReactNode, useEffect, useRef } from 'react';
import styles from './NavWrapper.module.scss';

export function NavWrapper({
  children,
  className,
  start,
  alwaysStickyNavButtons,
}: {
  children: ReactNode;
  className?: string;
  start?: boolean;
  alwaysStickyNavButtons?: boolean;
}) {
  const navRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (!navRef.current) {
        return;
      }

      const navPosition = navRef.current.getBoundingClientRect().bottom ?? 0;

      if (navPosition - window.innerHeight < -1) {
        navRef.current.setAttribute('data-in-view', '');
      } else {
        navRef.current.removeAttribute('data-in-view');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={navRef}
      className={classNames(
        styles.navWrapper,
        start && styles.start,
        styles.sticky,
        !alwaysStickyNavButtons && styles.nonSticky,
        className
      )}
    >
      {children}
    </div>
  );
}
