export const rootKeys = {
  lenders: () => ['lenders'] as const,
  brands: () => ['brands'] as const,
  applicationStatuses: () => ['application statuses'] as const,
  quoteStatuses: () => ['quote statuses'] as const,
  requestStatuses: () => ['request statuses'] as const,
  searchIndex: () => ['search index'] as const,
  loanApplication: () => ['loan application'] as const,
  clients: () => ['clients'] as const,
  availableWorkingLists: () => ['working lists'] as const,
  allWorkingLists: () => ['all working lists'] as const,
};
